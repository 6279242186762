"use client";

import { type AxiosError, isAxiosError } from "axios";
import { Button, Typography, WithFloatingLayout } from "design-system-ui";
import Image from "next/image";
import { useEffect, useState } from "react";
import { openOutlink } from "shared-utils";
import { settings } from "@/configs/settings";
import { ERROR_CONTENT } from "@/constants/errorContent";

export default function Error({
  error
}: {
  error: (Error & { digest?: string; response?: any }) | AxiosError;
  reset: () => void;
}) {
  const isNetworkError = isAxiosError(error);
  const [errorStatue, setErrorStatue] = useState("SERVER_ERROR");
  const { title, messages } = ERROR_CONTENT[errorStatue];
  const multiMessageGap = messages.length > 1 ? "tw-mt-20" : "";

  useEffect(() => {
    if (isNetworkError) {
      setErrorStatue("SERVER_ERROR");
      return;
    }

    return setErrorStatue("CLIENT_ERROR");
  }, [error]);

  return (
    <WithFloatingLayout
      floatingUnit={
        <>
          <Button
            buttonType={"button-fill-normal-primary"}
            onClick={() =>
              openOutlink("https://pyces.test-app.link/kmxTyR43LGb")
            }
            size="large"
          >
            <Typography variant="title2">쏘카일레클 설치하러 가기</Typography>
          </Button>
        </>
      }
    >
      <div className="tw-px-20 tw-text-center tw-justify-center tw-items-center tw-mt-[calc(100%/2)] tw-relative">
        <Typography
          variant="title1"
          color="gray700"
          as="div"
          className="tw-mb-8"
        >
          {title}
        </Typography>

        {messages?.map((message: string, index: number) => {
          return (
            <Typography
              variant="body3"
              color="gray500"
              as="div"
              className={`tw-whitespace-pre-line ${multiMessageGap}`}
              key={index}
            >
              <div key={index}>{message}</div>
            </Typography>
          );
        })}
        <Image
          className="tw-m-auto tw-mt-44"
          src={`${settings.CDN_DOMAIN}/etc/error_icon.webp`}
          width={88}
          height={88}
          alt="이용불가 아이콘"
        />
      </div>
    </WithFloatingLayout>
  );
}
